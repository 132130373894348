// Customizable Area Start
import React from "react";
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, OutlinedInput, styled, Typography } from "@mui/material";
import NewPasswordController, { configJSON, PasswordFormValues, Props, Touched } from "./NewPasswordController.web";
import { checkRadio, imgPasswordVisible, opNotesLogo, unCheckRadio, visibilityDisabled } from "./assets";
import { customPassStyles as passStyles } from "./ResetPassword.web";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import { customStyles } from "../../email-account-login/src/EmailAccountSignup.web";

// Customizable Area End

export default class CreateNewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getErrorMessage = (
      touched: FormikTouched<Touched>,
      errors: FormikErrors<PasswordFormValues>,
      value: string
    ) => {
      return (
        touched[value as keyof PasswordFormValues] &&
        errors[value as keyof PasswordFormValues] && (
          <Typography
            style={customStyles.errorText}
            data-test-id="error"
          >
            {errors[value as keyof PasswordFormValues]}
          </Typography>
        )
      );
    };

  renderErrors = () => {
      return(
        <>
        {this.state.updatePassCommonErr.length > 0 &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ marginTop:'20px',...customStyles.alertBox}}>
              <Box sx={customStyles.errorBox}/>
              <Box data-test-id="updatePasswordError" sx={customStyles.commonError}>
              {this.state.updatePassCommonErr}
              </Box>
            </Box>
          </Grid>
        }
        </>
      )
  }  
  // Customizable Area End
  render(){
        // Customizable Area Start
        // Customizable Area End
    return(
        // Customizable Area Start
        <>
            <Grid container>
              <Box style={styles.logoContainer}>
                <img alt="opNotes" src={opNotesLogo} />
              </Box>
            </Grid>
            <Container style={styles.outerContainer}>
              <Grid container style={styles.wrapper}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography style={passStyles.headerText}>{configJSON.newPasswordText}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Formik 
                    initialValues={{ 
                      confirmPasswordField : "",
                      newPasswordField:""
                    }}
                    validationSchema={this.passwordSchema}
                    validateOnBlur
                    validateOnChange
                    onSubmit={(values: PasswordFormValues) => {
                      this.handleSetNewPassword(values)
                    }}
                    >
                      {({touched, errors, values, handleChange}) => (
                        <Form style={styles.form} noValidate>
                          <Grid container style={styles.formWrapper}>
                            {this.renderErrors()}
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                              <FormControl fullWidth>
                                <label style={styles.labelTitle}>{configJSON.yourNewPasswordText}</label>
                                <OutlinedInput
                                  sx={styles.inputField}
                                  data-test-id="new-password"
                                  value={this.state.newPasswordInput}
                                  name={"newPasswordField"}
                                  type={this.state.isNewPasswordVisible ? "text" : "password"}
                                  placeholder={"Your password"}
                                  onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleNewPasswordInput(event.target.value)
                                    handleChange(event)
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        onMouseUp={() => {}}
                                        onMouseDown={() => {}}
                                        edge="end"
                                        onClick={this.handleShowNewPasswordInput}
                                      >
                                      {this.state.isNewPasswordVisible ?  <img src={imgPasswordVisible} /> : <img src={visibilityDisabled} /> }
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                /> 
                                {this.getErrorMessage(touched,errors, "newPasswordField")}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <FormControl fullWidth>
                                <label style={styles.labelTitle}>{configJSON.confirmYourNewPasswordLabel}</label>
                                <OutlinedInput
                                  sx={styles.inputField}
                                  data-test-id="confirm-password"
                                  value={this.state.confirmPasswordInput}
                                  name={"confirmPasswordField"}
                                  type={this.state.isConfirmPasswordVisible ? "text" : "password"}
                                  placeholder={"Your password"}
                                  onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleConfirmPasswordInput(event.target.value)
                                    handleChange(event)
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        onMouseUp={() => {}}
                                        onMouseDown={() => {}}
                                        edge="end"
                                        onClick={this.handleShowConfirmPasswordInput}
                                      >
                                      {this.state.isConfirmPasswordVisible ?  <img src={imgPasswordVisible} /> : <img src={visibilityDisabled} /> }
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                /> 
                                {this.getErrorMessage(touched,errors, "confirmPasswordField")}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={styles.list}>
                              <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <PasswordFormLabel label={configJSON.mustContainOneUppercase} labelPlacement="end"
                                    control={<Checkbox sx={{
                                      "& .MuiSvgIcon-root": {
                                        display:'none',
                                        borderRadius: '6px'
                                    }}}
                                    icon={<img src={unCheckRadio} />}
                                    checked={this.state.validatePassword.hasOneUpperCase}
                                    checkedIcon={<img src={checkRadio} />}
                                    />}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <PasswordFormLabel label={configJSON.mustContainOneLowercase} labelPlacement="end"
                                    control={<Checkbox sx={{
                                      "& .MuiSvgIcon-root": {
                                        display:'none',
                                        borderRadius: '6px'
                                    }}}
                                    icon={<img src={unCheckRadio} />}
                                    checked={this.state.validatePassword.hasOneLowerCase}
                                    checkedIcon={<img src={checkRadio} />}
                                    />}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <PasswordFormLabel label={configJSON.mustContainOneNumber} labelPlacement="end"
                                    control={<Checkbox sx={{
                                      "& .MuiSvgIcon-root": {
                                        display:'none',
                                        borderRadius: '6px'
                                    }}}
                                    icon={<img src={unCheckRadio} />}
                                    checked={this.state.validatePassword.hasOneNumber}
                                    checkedIcon={<img src={checkRadio} />}
                                    />}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <PasswordFormLabel label={configJSON.minEightCharacterLengthText} labelPlacement="end"
                                    control={<Checkbox sx={{
                                      "& .MuiSvgIcon-root": {
                                        display:'none',
                                        borderRadius: '6px'
                                    }}}
                                    icon={<img src={unCheckRadio} />}
                                    checked={this.state.validatePassword.hasMinEightChar}
                                    checkedIcon={<img src={checkRadio} />}
                                    />}
                                  />
                                </Grid>
                              </Grid> 
                            </Grid>
                            <StyledSubmitButton data-test-id="setPassBtn" type="submit">{configJSON.setNewPassButton}</StyledSubmitButton>
                            <StyledBackBtn>{configJSON.backText} <span data-test-id="loginButton" style={passStyles.loginBtn} onClick={this.handleBackToLoginScreen}>{configJSON.logInBtnText}</span></StyledBackBtn>
                          </Grid>
                        </Form> 
                      )} 
                  </Formik>
                </Grid>
              </Grid>  
            </Container>
        </>
        // Customizable Area End
    )
  }
}

// Customizable Area Start
const styles = {
  logoContainer: {
    padding: '24px',
    backgroundColor: '#fff',
    width: '100%'
  },
  outerContainer: {
    backgroundColor:'#F1F5F9',
    marginTop: '80px'
  },
  wrapper: {
    margin: 'auto',
    textAlign: 'center' as const,
    maxWidth: '327px',
    width: '100%',
  },
  formWrapper : {
    display: 'flex',
    flexDirection: 'column' as const,
    width: "327px",
    margin:'0px auto 0',
    gap:'20px'
  },
  form: {
    marginTop:'20px',
    display: 'flex',
    alignItems: "center"
  },
  inputField: {
    '&.MuiOutlinedInput-root': {
      fontSize:'15px',
      marginTop:'6px',
      borderRadius: '8px',
    }
  },
  labelTitle: {
    textAlign: 'left' as const,
    lineHeight: "22px",
    fontWeight: 700,
    fontSize: "16px",
    color: '#334155',
    textTransform: 'none' as const,
    fontFamily: 'Inter, sans-serif',
  },
  list: {
    textAlign: 'left' as const
  }
}

const PasswordFormLabel = styled(FormControlLabel)({
  span: {
    fontSize: "14px",
    color: '#0F172A',
    lineHeight: "22px",
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    cursor: 'default'
  },
  cursor: 'default'
})

const StyledSubmitButton = styled(Button)({
  borderRadius:'8px',
  textAlign: 'center' as const,
  backgroundColor:"#0E387A",
  width:'100%',
  fontSize: "16px",
  fontFamily: 'Inter-Bold, sans-serif',
  fontWeight: 700,
  color: '#F1F5F9',
  lineHeight: "24px",
  textTransform: 'none' as const ,
  padding:'16px',
  '&:hover': {
    color: '#F1F5F9',
    backgroundColor:"#0E387A"
  }
})

const StyledBackBtn = styled(Typography)({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  textAlign: 'center' as const,
  margin: '10px auto 0',
  fontSize: "16px",
  lineHeight: "22px",
  color: '#0F172A'
})
// Customizable Area End

 