// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import * as Yup from "yup";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";

export interface Touched {
  newPasswordField: boolean;
  confirmPasswordField: boolean;
}

export interface PasswordFormValues {
    confirmPasswordField : string,
    newPasswordField: string
}

interface UpdatePasswordRes {
  message: string,
  errors: {
    token: string
  }[]
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  newPasswordInput: string,
  confirmPasswordInput : string,
  isNewPasswordVisible: boolean,
  isConfirmPasswordVisible: boolean,
  validatePassword: {
    hasOneUpperCase: boolean,
    hasOneLowerCase: boolean, 
    hasOneNumber: boolean,
    hasMinEightChar : boolean
  }
  isPasswordSet: boolean,
  token: string,
  updatePassCommonErr: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updatePasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
        newPasswordInput: '',
        confirmPasswordInput : '',
        isNewPasswordVisible: false,
        isConfirmPasswordVisible: false,
        validatePassword: {
            hasOneUpperCase: false,
            hasOneLowerCase: false, 
            hasOneNumber: false,
            hasMinEightChar : false
        },
        isPasswordSet: false,
        token: '',
        updatePassCommonErr: ''
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } 
        else {
          this.handleUpdatePasswordResponse(responseJson, apiRequestCallId)
        } 
      }
    }
    // Customizable Area End
  }

  handleUpdatePasswordResponse = (responseJson: unknown, apiRequestCallId: string) => {
    const response = responseJson as UpdatePasswordRes
    if(apiRequestCallId === this.updatePasswordApiCallId) {
      if(response.message === "You have created your new password"){
        this.props.navigation.navigate("EmailAccountLoginBlock")
      }
      if(response.errors && response.errors[0].token === "Invalid token") {
        this.setState({ updatePassCommonErr: response.errors[0].token })
      }
    }
  }

  handleNewPasswordInput = (value: string) => {
    if(value) {
        this.setState({ 
            newPasswordInput: value
        })
    }
    else {
      this.setState({ 
        newPasswordInput: ''
      })
    }
    this.setState({
      validatePassword: {
        hasOneUpperCase: /[A-Z]/.test(value),
        hasOneLowerCase: /[a-z]/.test(value),
        hasOneNumber: /^(?=.*\d)(?=.*[^\w\s]).+$/.test(value),
        hasMinEightChar: value.length >= 12
      }
    })
  }

  handleConfirmPasswordInput = (value: string) => {
    if(value) {
        this.setState({ confirmPasswordInput: value })
    }
  }

  handleShowNewPasswordInput = () => {
    this.setState({ isNewPasswordVisible : !this.state.isNewPasswordVisible})
  }

  handleShowConfirmPasswordInput = () => {
    this.setState({ isConfirmPasswordVisible : !this.state.isConfirmPasswordVisible})
  }

  handleBackToLoginScreen = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  handleSetNewPassword = (values: PasswordFormValues) => {
    if(values.newPasswordField && values.confirmPasswordField) {
        this.setState({ isPasswordSet : true })
        this.updatePassword()
    }
  }

  async componentDidMount(): Promise<void> {
     const path = window.location.href
     const tokenValue =  path.split("token=")[1]
     this.setState({ token : tokenValue})
  }

  passwordSchema = () => {
    return Yup.object().shape({
      newPasswordField: Yup.string()
      .required("New password is required"),
      confirmPasswordField: Yup.string()
      .required("Confirm password is required")
      .when("newPasswordField", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPasswordField")],
          "Password must match"
        ),
      }),
    })
  }

  updatePassword = () => {
    const headers = {
      token: this.state.token
    };

    const formData = new FormData()
   
    formData.append("set_new_password",this.state.newPasswordInput )
    formData.append("confirm_password",this.state.confirmPasswordInput )

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePasswordApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
