import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React , { RefObject } from "react";
import * as Yup from "yup";

export interface Medication {
  medication: string,
  dosage: string,
  frequency: string
}

export interface MedicationFormValues {
  medications : Medication[]
}

export interface PatientFormValues {
  patientPhoto: string;
  firstName: string;
  middleName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  dateOfBirth: Date | null,
  procedure: string;
  dateOfProcedure: Date | null;
  clinical: string;
  medications : Medication[]
  
}

export interface CareTakerFields {
  id: string,
  firstName: string,
  middleName: string,
  lastName: string,
  email: string
}

export interface CareTakerFormValues {
  careTaker: CareTakerFields[]
}

export interface TouchedMedications {
  medications : {
    medication: boolean,
    dosage: boolean,
    frequency: boolean
  }[]
}

export interface TouchedCareTaker {
  careTaker: {
    id: boolean,
    firstName: boolean,
    middleName: boolean,
    lastName: boolean,
    email: boolean
  }[]
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  patientPhoto: boolean;
  firstName: boolean;
  middleName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  dateOfBirth: boolean,
  procedure: boolean;
  dateOfProcedure: boolean;
  clinical: boolean;
  medications : {
    medication: boolean,
    dosage: boolean,
    frequency: boolean
  }[],
  careTaker: {
    firstName: boolean,
    middleName: boolean,
    lastName: boolean,
    email: boolean
  }[]
}

export interface Error {
  patientPhoto: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  dateOfBirth: string,
  procedure: string;
  dateOfProcedure: string;
  clinical: string
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  patientImage: string;
  imageName: string,
  firstName: string;
  middleName: string,
  lastName: string;
  mobileNumber: string,
  procedure: string,
  medication: string,
  dateOfProcedure: Date | null,
  dosage: string,
  clinical: string,
  frequency: string,
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  step: number,
  dateOfBirth: Date | null,
  openCalendar: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  fileInputRef: RefObject<HTMLInputElement>
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      patientImage: "",
      imageName: "",
      firstName: "",
      middleName: "",
      lastName: "",
      mobileNumber: "",
      procedure: "",
      medication: "",
      dateOfProcedure: null,
      dosage: "",
      clinical: "",
      frequency: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      step: 0,
      dateOfBirth: null,
      openCalendar: false
      // Customizable Area End
    };

    // Customizable Area Start
    this.fileInputRef = React.createRef()
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleFileChange = (file: File) => {
    if(file) {
      this.setState({ patientImage: URL.createObjectURL(file) , imageName: file.name})
    }
  } 

  handleTextfieldClick = () => {
    if(this.fileInputRef && this.fileInputRef.current) {
      this.fileInputRef.current.click()
    }
  }

  handleFirstName = (value: string) => {
    this.setState({ firstName: value})
  }

  handleMiddleName = (value: string) => {
    this.setState({ middleName: value})
  }

  handleLastName = (value: string) => {
    this.setState({ lastName: value})
  }

  handleEmail = (value: string) => {
    this.setState({ email: value})
  }

  handleMobileNumber = (value: string) => {
    this.setState({ mobileNumber: value})
  }

  handleProcedure = (value: string) => {
    this.setState({ procedure: value})
  }

  handleDateOfProcedure = (value: Date) => {
    this.setState({ dateOfProcedure: value})
  }

  handleClinical = (value: string) => {
    this.setState({ clinical: value})
  }

  handleDob = (value:Date) => {
    this.setState({ dateOfBirth: value })
  }

  formSchema = () => {
    return Yup.object().shape({
      patientPhoto: Yup.string()
        .required("Patient Photo is required"),
      firstName: Yup.string()
        .required("First name is required"),
      middleName: Yup.string()
        .required("Middle name is required"),
      lastName: Yup.string()
        .required("Last name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email format"),
      mobileNumber:  Yup.string()
        .required("Mobile Number is required"),  
      dateOfBirth:  Yup.string()
      .required("Date of Birth is required")
      .nullable(),
      procedure: Yup.string()
        .required("Procedure is required"),
      dateOfProcedure: Yup.string()
      .required("Date of Procedure is required")
      .nullable(),
      clinical: Yup.string()
      .required("Clinical is required"),
      medications: Yup.array().of(
        Yup.object().shape({
          medication: Yup.string()
          .required("Medication is required"),
          dosage: Yup.string()
          .required("Dosage is required"),
          frequency: Yup.string()
          .required("Frequency is required"),
        })
      )   
    })
  }

  handleNext = (values:PatientFormValues) => {
    if(values) {
      this.setState({ step: this.state.step + 1})
    }
  }

  careTakerFormSchema = () => {
    return Yup.object().shape({
      careTaker: Yup.array().of(
        Yup.object().shape({
        firstName: Yup.string()
          .required("First name is required"),
        middleName: Yup.string()
          .required("Middle name is required"),
        lastName: Yup.string()
          .required("Last name is required"),
        email: Yup.string()
          .required("Email is required")
          .email("Invalid email format"),
        })
      )
    })
  }

  navigateToStepThree = (values:CareTakerFormValues) => {
    if(values) {
      this.setState({ 
        step: this.state.step + 1
      })
    }
  }

  handleBackFromCareTaker = () => {
    this.setState({ 
      step: this.state.step - 1
    })
  }
  // Customizable Area End
}
