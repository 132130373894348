// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  FormControl,
  Avatar,
  InputAdornment,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { styled } from "@mui/styles";
import { FieldArray, Formik, FormikErrors, FormikTouched } from "formik"
const photoLibrary = require("../assets/photo_library.png")
const calendarIcon = require("../assets/calendarIcon.png")
const backIcon = require("../assets/backBtn.png")
const nextIcon = require("../assets/nextBtn.png")
import { v4 as uuidv4 } from "uuid"


import CustomFormController, {
  Props,
  Touched,
  Error,
  configJSON,
  MedicationFormValues,
  Medication,
  PatientFormValues,
  CareTakerFormValues,
  CareTakerFields,
  TouchedMedications,
  TouchedCareTaker,
} from "./CustomFormController.web"
import DatePicker from "react-datepicker"
import dayjs from "dayjs"
import "react-datepicker/dist/react-datepicker.css"

// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          data-test-id="error"
          style={{ 
            marginTop: "2px", 
            fontFamily:'Inter-bold, sans-serif', 
            fontWeight: 700, 
            fontSize: "12px", 
            color: "#dc2626" 
          }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  getMedicationErrorMessage = (
    touched: FormikTouched<TouchedMedications>,
    errors: FormikErrors<MedicationFormValues>,
    index: number,
    value: keyof Medication
  ) => {
    return (
      <Typography
      data-test-id="errorMessage"
      style={{ 
        marginTop: "2px", 
        fontFamily:'Inter-bold, sans-serif', 
        fontWeight: 700, 
        fontSize: "12px", 
        color: "#dc2626" 
      }}
    >
      {touched.medications && errors.medications && (errors.medications[index] as Medication) && (errors.medications[index] as Medication)[value]}
    </Typography>
    )
  };

  getCareTakerErrorMessage = (
    touched: FormikTouched<TouchedCareTaker>,
    errors: FormikErrors<CareTakerFormValues>,
    index: number,
    value: keyof CareTakerFields
  ) => {
    return (
      <Typography
      data-test-id="errorMessage"
      style={{ 
        marginTop: "2px", 
        fontFamily:'Inter-bold, sans-serif',
        fontWeight: 700, 
        fontSize: "12px", 
        color: "#dc2626" 
      }}
    >
      {touched.careTaker && errors.careTaker && (errors.careTaker[index] as CareTakerFields) && (errors.careTaker[index] as CareTakerFields)[value]}
    </Typography>
    )
  };

  renderStepProgress = (step: number) => {
    return (
      <Box sx={webStyle.stepper}>
        <Typography sx={webStyle.stepLabelText}>{this.state.step + 1}. {configJSON.steps[this.state.step]}</Typography>
        <Box style={webStyle.progressBar}>
          {configJSON.steps.map(( _:number,index:number) => {
            return(
            <Box
              key={index}
              sx={{
                flex:1,
                height: '4.88px',
                borderRadius: 75,
                backgroundColor: index === step ? "#334155" :"#e0e0e0" ,
                mx: 0.5
              }}
            />
          )})}
        </Box>
      </Box>
    )
  }

  renderCareTakerInformation = () => {
    return(
      <>
        <OuterGrid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography style={webStyle.headerLabel}>{configJSON.addCareTakerInfoLabel}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography style={webStyle.subHeaderLabel}>{configJSON.addCareTakerInfoLabel}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
          <Formik
            data-test-id="careTakerFormik"
            initialValues={{
              careTaker: [
                {
                  id: uuidv4(),
                  firstName: "",
                  middleName: "",
                  lastName: "",
                  email: ""
                }
              ]
            }}
            validationSchema={this.careTakerFormSchema}
            onSubmit={(values: CareTakerFormValues) => {
              this.navigateToStepThree(values)
            }}
          >
            {({values, touched, errors, handleSubmit, handleChange }) => (
              <form data-test-id="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                <FieldArray name="careTaker" data-test-id="fieldArray">
                  {({push, remove}) => (
                    <>
                    <Grid container columnGap={4} rowGap={2} style={{marginTop:'6px',...webStyle.bottom}}>
                      {values.careTaker.map((fields: CareTakerFields, index:number)=> (
                        
                          <Grid item xs={12} md={3} lg={3} rowGap={2} key={fields.id}>
                            <FormControl fullWidth>
                              <label style={webStyle.labelText}>{configJSON.placeHolderFirstName}</label>
                              <TextField
                                name={`careTaker[${index}].firstName`}
                                fullWidth
                                data-test-id="careTakerFirstName"
                                variant="outlined"
                                type="text"
                                sx={webStyle.photoField}
                                placeholder={configJSON.placeHolderFirstName}
                                onChange={handleChange}
                              />
                              {this.getCareTakerErrorMessage(touched, errors, index, "firstName")}
                            </FormControl>
                            <FormControl fullWidth style={webStyle.fieldMargin}>
                              <label style={webStyle.labelText}>{configJSON.middleNameLabel}</label>
                              <TextField
                                name={`careTaker[${index}].middleName`}
                                fullWidth
                                data-test-id="careTakerMiddleName"
                                variant="outlined"
                                type="text"
                                sx={webStyle.photoField}
                                placeholder={configJSON.middleNameLabel}
                                onChange={handleChange}
                              />
                              {this.getCareTakerErrorMessage(touched, errors, index, "middleName")}
                            </FormControl>
                            <FormControl fullWidth style={webStyle.fieldMargin}>
                              <label style={webStyle.labelText}>{configJSON.placeHolderLastName}</label>
                              <TextField
                                name={`careTaker[${index}].lastName`}
                                fullWidth
                                data-test-id="careTakerLastName"
                                variant="outlined"
                                type="text"
                                sx={webStyle.photoField}
                                placeholder={configJSON.placeHolderLastName}
                                onChange={handleChange}
                              />
                              {this.getCareTakerErrorMessage(touched, errors, index, "lastName")}
                            </FormControl>
                            <FormControl fullWidth style={webStyle.fieldMargin}>
                              <label style={webStyle.labelText}>{`${configJSON.placeHolderEmail} ${configJSON.placeHolderAddress}`}</label>
                              <TextField
                                name={`careTaker[${index}].email`}
                                fullWidth
                                data-test-id="careTakerEmail"
                                variant="outlined"
                                type="text"
                                sx={webStyle.photoField}
                                placeholder={`${configJSON.placeHolderEmail} ${configJSON.placeHolderAddress}`}
                                onChange={handleChange}
                              />
                              {this.getCareTakerErrorMessage(touched, errors, index, "email")}
                            </FormControl>
                          {index > 0 && 
                            <Box style={{display:'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                              <Button key={index} data-test-id="removeBtn" style={webStyle.errorText} onClick={() => {
                                remove(index)
                              }}>{configJSON.removeButtonText}</Button>
                            </Box>
                          }
                          </Grid>
                      ))}
                      </Grid>
                      {values.careTaker.length < 3 &&
                        <Grid item xs={12} sx={{mb: 2}}>
                          <Typography data-test-id="addCaretakerBtn" style={webStyle.addMedicationBtn} 
                            onClick={() => {
                              values.careTaker.length < 3 && push({ id: uuidv4(), firstName: "", middleName: "", lastName: "", email: ""})
                            }}
                            >{configJSON.addCaretakerBtnLabel}</Typography>
                        </Grid>
                      }
                    </>
                  )}
                </FieldArray>
                <Grid item xs={12} sm={12} md={12} lg={12} style={webStyle.buttonContainer}>
                  <BackButton data-test-id="careTakerBack" onClick={this.handleBackFromCareTaker}>
                    <span style={webStyle.arrowLeft}><img src={backIcon} alt="backArrow" /></span>
                    {configJSON.backButtonText}
                  </BackButton>
                  <NextButton type="submit" data-test-id="nextTwo">
                    {configJSON.nextButtonText}
                    <span style={webStyle.arrowRight}><img src={nextIcon} alt="backArrow" /></span>
                  </NextButton>
                </Grid> 
              </form>
            )}  
          </Formik>
          </Grid>
        </OuterGrid>  
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       {this.renderStepProgress(this.state.step)}
        {this.state.step === 0 && 
          <OuterGrid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography style={webStyle.headerLabel}>{configJSON.addPatientAccountLabel}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography style={webStyle.subHeaderLabel}>{configJSON.addPatientInfoLabel}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <Formik
              data-test-id="formik"
              initialValues={{
                patientPhoto: this.state.patientImage,
                firstName: this.state.firstName,
                middleName: this.state.middleName,
                lastName: this.state.lastName,
                email: this.state.email,
                mobileNumber: this.state.mobileNumber,
                dateOfBirth: this.state.dateOfBirth,
                procedure: this.state.procedure,
                medications: [
                  {
                    medication: "",
                    dosage: "",
                    frequency: ""
                  }
                ],
                dateOfProcedure: this.state.dateOfProcedure,
                clinical: this.state.clinical
              }}
              validationSchema={this.formSchema}
              onSubmit={(values:PatientFormValues) => {
                this.handleNext(values)
              }}
            >
              {({values, touched, errors, setFieldValue, handleSubmit, handleChange }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <Grid container columnGap={5} rowGap={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3} style={webStyle.fieldSpacing}>
                      <Grid container rowGap={2} columnGap={3}>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <label style={webStyle.labelText}>{configJSON.patientPhotoLabel}</label>
                            <div data-test-id="imageField" onClick={this.handleTextfieldClick}>
                            <input 
                                type="file"
                                accept="image/*"
                                id="image-upload"
                                ref={this.fileInputRef}
                                style={{display:'none'}}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  if(event.target.files) {
                                    this.handleFileChange(event.target.files[0])
                                    setFieldValue("patientPhoto",URL.createObjectURL(event.target.files[0]))
                                  }
                                  
                                }}
                                data-test-id="uploadPhoto"
                              />
                            <TextField
                              data-test-id="photoField"
                              fullWidth
                              variant="outlined"
                              value={this.state.imageName}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '7.67px',
                                  fontFamily: 'Inter, sans-serif',
                                  fontWeight: 400,
                                  fontSize: '15px',
                                  lineHeight: '21.09px',
                                  color: '#94A3B8',
                                  cursor: 'pointer',
                                },
                                cursor: 'pointer',
                                input: {
                                  marginLeft: this.state.patientImage ? '12px': '0'
                                }
                              }}
                              placeholder="Upload Photo"
                              InputProps={{
                                startAdornment: this.state.patientImage ? 
                                  <Avatar src={this.state.patientImage} sx={webStyle.avatar} />
                                  :
                                  null,
                                endAdornment: (
                                  <InputAdornment position="end" sx={{
                                    "& .MuiInputAdornment-root": {
                                      marginLeft: '0'
                                    }
                                  }}>
                                    <IconButton component="span"
                                      sx={{
                                        '&.MuiIconButton-root': {
                                            paddingRight: 0
                                        }
                                      }}
                                    >
                                      <img src={photoLibrary} alt="photoLibrary" />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                readOnly: true,
                                sx: {pointerEvents: 'none'}  
                              }}
                            />
                            {this.getErrorMessage(touched, errors, "patientPhoto")}
                            </div>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <label style={webStyle.labelText}>{configJSON.placeHolderFirstName}</label>
                            <TextField
                              data-test-id="firstName"
                              fullWidth
                              variant="outlined"
                              type="text"
                              value={this.state.firstName}
                              sx={webStyle.photoField}
                              placeholder={configJSON.placeHolderFirstName}
                              onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                                this.handleFirstName(event.target.value)
                                setFieldValue("firstName", event.target.value);
                              }}
                            />
                            {this.getErrorMessage(touched, errors, "firstName")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <label style={webStyle.labelText}>{configJSON.middleNameLabel}</label>
                            <TextField
                              fullWidth
                              data-test-id="middleName"
                              variant="outlined"
                              type="text"
                              value={this.state.middleName}
                              sx={webStyle.photoField}
                              placeholder={configJSON.middleNameLabel}
                              onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                                this.handleMiddleName(event.target.value)
                                setFieldValue("middleName", event.target.value);
                              }}
                            />
                            {this.getErrorMessage(touched, errors, "middleName")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <label style={webStyle.labelText}>{configJSON.placeHolderLastName}</label>
                            <TextField
                              fullWidth
                              data-test-id="lastName"
                              variant="outlined"
                              type="text"
                              value={this.state.lastName}
                              sx={webStyle.photoField}
                              placeholder={configJSON.placeHolderLastName}
                              onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                                this.handleLastName(event.target.value)
                                setFieldValue("lastName", event.target.value);
                              }}
                            />
                            {this.getErrorMessage(touched, errors, "lastName")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <label style={webStyle.labelText}>{configJSON.placeHolderEmail} {configJSON.placeHolderAddress}</label>
                            <TextField
                              fullWidth
                              data-test-id="email"
                              variant="outlined"
                              type="email"
                              value={this.state.email}
                              sx={webStyle.photoField}
                              placeholder={`${configJSON.placeHolderEmail} ${configJSON.placeHolderAddress}`}
                              onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                                this.handleEmail(event.target.value)
                                setFieldValue("email", event.target.value);
                              }}
                            />
                            {this.getErrorMessage(touched, errors, "email")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <label style={webStyle.labelText}>{configJSON.mobileNumberLabel}</label>
                            <TextField
                              fullWidth
                              data-test-id="mobileNumber"
                              variant="outlined"
                              value={this.state.mobileNumber}
                              sx={webStyle.photoField}
                              placeholder={configJSON.mobileNumberLabel}
                              onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                                this.handleMobileNumber(event.target.value)
                                setFieldValue("mobileNumber", event.target.value);
                              }}
                            />
                            {this.getErrorMessage(touched, errors, "mobileNumber")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                          <label style={webStyle.labelText}>{configJSON.dateOfBirthLabel}</label>
                            <DatePicker 
                              dateFormat="dd-MM-yyyy"
                              placeholderText="dd-mm-yyyy"
                              data-test-id="dateOfBirth"
                              selected={this.state.dateOfBirth}
                              onChange={(value: Date) => {
                                  this.handleDob(value)
                                  setFieldValue("dateOfBirth", value)
                              }}
                              customInput={
                                <TextField
                                  sx={webStyle.photoField}
                                  fullWidth
                                  value={dayjs(this.state.dateOfBirth).format("DD MM YYYY")}
                                  inputProps={{
                                    sx:{
                                      'react-datepicker__day--selected':{
                                        backgroundColor : '#f3ebff'
                                      }
                                    },
                                    style: {
                                      cursor: 'pointer'
                                    }
                                  }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton sx={{
                                        "&.MuiIconButton-root": {
                                          marginLeft: '-10px'
                                        }
                                      }}>
                                        <img src={calendarIcon} data-test-id="calendar" />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  }}
                                  />
                                }
                            />
                            {this.getErrorMessage(touched, errors, "dateOfBirth")}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} style={webStyle.fieldSpacing}>
                      <Grid container columnGap={2} rowGap={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormControl fullWidth>
                            <label style={webStyle.labelText}>{configJSON.procedureLabel}</label>
                            <Select
                              data-test-id="procedure"
                              value={this.state.procedure}
                              displayEmpty
                              renderValue={(selected) => 
                                selected ? selected : <Typography sx={webStyle.selectPlaceholder}>Procedure</Typography>
                              }
                              MenuProps={{
                                PaperProps: {
                                  sx: webStyle.menuStyle
                                },
                                MenuListProps: {
                                  sx:  webStyle.menuListStyle
                                }
                              }}
                              onChange={(event: SelectChangeEvent<string>) => {
                                this.handleProcedure(event.target.value)
                                setFieldValue("procedure", event.target.value);
                              }}
                              sx={webStyle.select}
                              >
                                {configJSON.procedureOptions.map((option: string) => {
                                  return(
                                    <MenuItem value={option}>{option}</MenuItem>
                                  )
                                })}
                                
                            </Select>
                            {this.getErrorMessage(touched, errors, "procedure")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                        <FormControl fullWidth>
                          <label style={webStyle.labelText}>{configJSON.dateOfProcedureLabel}</label>
                            <DatePicker 
                              data-test-id="dateOfProcedure"
                              dateFormat="dd MMMM yyyy"
                              placeholderText="dd mm yyyy"
                              selected={this.state.dateOfProcedure}
                              onChange={(value:Date) => {
                                  this.handleDateOfProcedure(value)
                                  setFieldValue("dateOfProcedure", value)
                              }}
                              customInput={
                                <TextField
                                  sx={webStyle.photoField}
                                  fullWidth
                                  value={dayjs(this.state.dateOfProcedure).format("dd MMMM yyyy")}
                                  inputProps={{
                                    sx:{
                                      'react-datepicker__day--selected':{
                                        backgroundColor : '#f3ebff'
                                      }
                                    },
                                    style: {
                                      cursor: 'pointer'
                                    }
                                  }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton sx={{
                                        "&.MuiIconButton-root": {
                                          marginLeft: '-10px'
                                        }
                                      }}>
                                        <img src={calendarIcon}  />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  }}
                                  />
                                }
                            />
                            {this.getErrorMessage(touched, errors, "dateOfProcedure")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <label style={webStyle.labelText}>{configJSON.clinicalLabel}</label>
                            <Select 
                              data-test-id="clinical"
                              value={this.state.clinical}
                              displayEmpty
                              renderValue={(selected) => 
                                selected ? selected : <Typography sx={webStyle.selectPlaceholder}>Name</Typography>
                              }
                              MenuProps={{
                                PaperProps: {
                                  sx: webStyle.menuStyle
                                },
                                MenuListProps: {
                                  sx:  webStyle.menuListStyle
                                }
                              }}
                              onChange={(event: SelectChangeEvent<string>) => {
                                this.handleClinical(event.target.value)
                                setFieldValue("clinical", event.target.value);
                              }}
                              sx={webStyle.select}
                              >
                                {configJSON.clinicalOptions.map((option: string) => {
                                  return(
                                    <MenuItem value={option}>{option}</MenuItem>
                                  )
                                })}
                                
                            </Select>
                            {this.getErrorMessage(touched, errors, "clinical")}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} style={webStyle.fieldSpacing}>
                        <FieldArray name="medications"  data-test-id="medicationFieldArray">
                          {({push}) => (
                            <>
                              {values.medications.map((med:Medication,index:number)=> (
                                <Grid container columnGap={2} rowGap={2} style={webStyle.bottom}>
                                  <Grid item xs={12}>
                                    <FormControl fullWidth>
                                      <label style={webStyle.labelText}>{configJSON.medicationLabel}</label>
                                      <Select 
                                        data-test-id="medication"
                                        name={`medications[${index}].medication`}
                                        displayEmpty
                                        renderValue={(value: React.ReactNode) => 
                                          value ? value : <Typography sx={webStyle.selectPlaceholder}>Medications</Typography>
                                        }
                                        onChange={handleChange}
                                        sx={webStyle.select}
                                        MenuProps={{
                                          PaperProps: {
                                            sx: webStyle.menuStyle
                                          },
                                          MenuListProps: {
                                            sx:  webStyle.menuListStyle
                                          }
                                        }}
                                        >
                                          {configJSON.medicationOptions.map((medication: string) => {
                                            return(
                                              <MenuItem value={medication}>{medication}</MenuItem>
                                            )
                                          })}
                                          
                                      </Select>
                                      {this.getMedicationErrorMessage(touched,errors,index, "medication")}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormControl fullWidth>
                                      <label style={webStyle.labelText}>{configJSON.dosageLabel}</label>
                                      <TextField
                                        fullWidth
                                        data-test-id="dosage"
                                        name={`medications[${index}].dosage`}
                                        variant="outlined"
                                        type="text"
                                        placeholder={configJSON.dosageText}
                                        sx={webStyle.photoField}
                                        onChange={handleChange}
                                      />
                                      {this.getMedicationErrorMessage(touched,errors,index, "dosage")}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormControl fullWidth>
                                      <label style={webStyle.labelText}>{configJSON.frequencyLabel}</label>
                                      <TextField
                                        name={`medications[${index}].frequency`}
                                        fullWidth
                                        data-test-id="frequency"
                                        variant="outlined"
                                        type="text"
                                        sx={webStyle.photoField}
                                        placeholder={configJSON.frequencyLabel}
                                        onChange={handleChange}
                                      />
                                      {this.getMedicationErrorMessage(touched,errors,index, "frequency")}
                                    </FormControl>
                                  </Grid>
                                </Grid>

                              ))}
                              {values.medications.length < 5 &&
                                <Grid item xs={12} sx={{mb: 2}}>
                                  <Typography data-test-id="addMedicationBtn" style={webStyle.addMedicationBtn} onClick={() => values.medications.length < 5 && push({ medication: "", dosage: "", frequency: ""})}>{configJSON.addMedication}</Typography>
                                </Grid>
                              }
                            </>
                          )}
                        </FieldArray>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={webStyle.buttonContainer}>
                    <BackButton>
                      <span style={webStyle.arrowLeft}><img src={backIcon} alt="backArrow" /></span>
                      {configJSON.backButtonText}
                    </BackButton>
                    <NextButton type="submit" data-test-id="next">
                      {configJSON.nextButtonText}
                      <span style={webStyle.arrowRight}><img src={nextIcon} alt="backArrow" /></span>
                    </NextButton>
                  </Grid> 
                </form>
              )}
            </Formik>
            </Grid>
          </OuterGrid>
        }
        {this.state.step === 1 && this.renderCareTakerInformation()}
      </>
      // Customizable Area End
    );
  }
}

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
  headerLabel: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '27px',
    fontWeight: 700,
    lineHeight: '21.09px',
    textTransform: 'capitalize' as const,
    color: '#334155'
  },
  subHeaderLabel : {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '21.09px',
    textTransform: 'capitalize' as const,
    color: '#334155',
    margin :'34px 0 10px'
  },
  avatar: {
    width: '30px', 
    height:'30px', 
    borderRadius: 0,
    'img': {
      objectFit: 'contain'
    }
  },
  photoField : {
    '& .MuiOutlinedInput-root': {
      borderRadius: '7.67px',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '21.09px',
      color: '#94A3B8',
      cursor: 'pointer'
    },
    cursor: 'pointer'
  },
  selectPlaceholder : {
    '&.MuiTypography-root': {
      borderRadius: '7.67px',
      lineHeight: '21.09px',
      color: '#94A3B8',
      cursor: 'pointer',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      fontSize: '15px',
    }
  },
  select : {
    borderRadius: '7.67px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21.09px',
    color: '#94A3B8',
    cursor: 'pointer',
    '& fieldset': {
      borderRadius: '7.67px',
    }
  },
  labelText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '21.09px',
    color: '#334155',
    marginBottom: '6px'
  },
  fieldSpacing: {
    marginTop: '10px'
  },
  text: {
    color: '#0F172A',
    '& .MuiOutlinedInput-root': {
      borderRadius: '7.67px',
      gap:'10px',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '21.09px',
      '&::placeholder': {
        color: '#94A3B8'
      }
    }
  },
  menuStyle: {
    width: '100%',
    maxWidth: '225px',
    height: '144px',
    minWidth: 'auto !important',
    borderRadius: '6px',
    marginTop: '-40px !important',
    boxShadow: "0 2px 4px #00000026",
    'ul' : {
      paddingTop: 0,
      paddingBottom: 0
    },
  },
  menuListStyle : {  
    backgroundColor: '#fff',
    '& .MuiMenuItem-root': {
      fontFamily: 'Inter, sans-serif',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
      color: '#0F172A',
      backgroundColor: '#fff',
      margin: '0',
      padding: '9px 12px',
      "&:hover": {
        backgroundColor: '#F3EBFF'
      }
    }
  },
  addMedicationBtn: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '21.09px',
    color: '#334155',
    cursor: 'pointer',
    textTransform: 'capitalize' as const,
    textDecoration: 'underline',
    border: 'none'
  },
  progressBar : {
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  stepper: { 
    width: '100%', 
    maxWidth: '337.47px', 
    marginLeft: "auto", 
    p: 3,
    textAlign: 'center'
  },
  stepLabelText: {
    '&.MuiTypography-root': {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '21.09px',
      marginBottom: '5px'
    }
  },
  bottom: {
    marginBottom: '20px'
  },
  buttonContainer: {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: '40px',
    paddingRight: '40px',
    gap: '30px'
  },
  errorText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21.09px',
    color: '#dc2626',
    textTransform: 'capitalize' as const
  },
  fieldMargin: {
    marginTop: '16px'
  },
  arrowLeft: {
    marginRight: '6px'
  },
  arrowRight: {
    marginLeft: '6px'
  }
};

const OuterGrid = styled(Grid)({
  padding: '10px 30px 40px',
  background: '#F1F5F9'
})

const BackButton = styled(Button)({
  '&.MuiButton-root': {
    padding: '10px 16px',
    border: '1px solid #0E387A',
    width:'150px',
    fontSize: "16px",
    borderRadius: "8px",
    textTransform: "capitalize" as const,
    fontFamily: "Inter, sans-serif",
    color: '#0E387A',
    fontWeight: 700,
    lineHeight: '24px',
  },
  marginRight: '24px'
})

const NextButton = styled(Button)({
  span: {
    marginRight: '16px'
  },
  '&.MuiButton-root': {
    padding: '10px 16px',
    border: '1px solid #0E387A',
    width:'150px',
    fontSize: "16px",
    borderRadius: "8px",
    textTransform: "capitalize" as const,
    fontFamily: "Inter, sans-serif",
    backgroundColor: '#0E387A',
    color: '#fff',
    fontWeight: 700,
    lineHeight: '24px',
    '&:hover': {
      backgroundColor: '#0E387A',
      color: '#fff'
    }
  }
})
// Customizable Area End
