Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";


exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";
exports.token="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod="PUT";
exports.fetchFormDataMethod="GET";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity="City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";

exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;
exports.addPatientAccountLabel = "Add Patient Account";
exports.addPatientInfoLabel = "Add Patient Information";
exports.patientPhotoLabel = "Patient Photo";
exports.middleNameLabel = "Middle Name";
exports.mobileNumberLabel = "Mobile Number";
exports.procedureLabel = "Procedure";
exports.medicationLabel = "Medication related to this procedure";
exports.dateOfProcedureLabel = "Date of Procedure";
exports.dosageLabel = "Dosage (mg)";
exports.dosageText = "Dosage";
exports.clinicalLabel = "Clinical";
exports.nameText = "Name";
exports.frequencyLabel = "Frequency";
exports.addMedication = "+ Add Medication";
exports.steps = ["Patient Information", "Caretaker Information", "Procedure and Tasks"]
exports.procedureOptions = ["Option 1", "Option 2", "Option 3", "Option 4"]
exports.medicationOptions = ["Medication 1", "Medication 2", "Medication 3", "Medication 4"]
exports. clinicalOptions = ["Clinical Provider 1", "Clinical Provider 2", "Clinical Provider 3", "Clinical Provider 4"];
exports.dateOfBirthLabel = "Date of Birth";
exports.backButtonText = "Back";
exports.nextButtonText = "Next";
exports.addCareTakerInfoLabel = "Add Caretaker Information";
exports.addCaretakerBtnLabel = "+ Add Caretaker";
exports.removeButtonText = "Remove";
// Customizable Area End