// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
export const configJSON = require("./config");

interface EmailResponse {
  message: string,
  otp: number
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  recoveryEmail: string
  resendEmailPopup: boolean,
  emailError: string,
  apiError: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendRecoveryEmailApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
        recoveryEmail: '',
        resendEmailPopup: false,
        emailError: '',
        apiError: ''
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } 
        else {
          this.handleResponse(responseJson, apiRequestCallId)
        } 
      }
    }
    // Customizable Area End
  }

  handleResponse = (responseJson: unknown, apiRequestCallId: string) => {
    const response = responseJson as EmailResponse
    if(apiRequestCallId === this.sendRecoveryEmailApiCallId) {
      if(response.message === "Account not found") {
        this.setState({ apiError: response.message })
      }
      if(response.otp) {
        this.setState({ resendEmailPopup: true })
      }
    }
  }

  handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value
    this.setState({ recoveryEmail: emailValue, apiError: ''})
    if(configJSON.emailRegex.test(emailValue)) {
        this.setState({ emailError: "" })
    }
    else this.setState({ emailError: "Invalid email" })
  }

  handleBackToLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  handleRecoveryLink = () => {
    if(this.state.recoveryEmail && !this.state.emailError) {
      this.sendRecoveryEmail()
    }
  }

  handleResendEmail = () => {
    this.sendRecoveryEmail()
  }

  sendRecoveryEmail = () => {
    const headers = {
    };

    const formData = new FormData()
   
    formData.append("email",this.state.recoveryEmail )

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendRecoveryEmailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendRecoveryEmailApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
