import React from "react";

// Customizable Area Start
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, OutlinedInput, styled, TextField, Typography} from '@mui/material';
import { customStyles as logStyles } from "../../email-account-login/src/EmailAccountSignup.web";
import { StyleSheet } from "react-native";
import { imgPasswordVisible, visibilityOff } from "./assets";

export const loginStyles = {
  loginContainer: {
    margin: 'auto',
    backgroundColor: '#F1F5F9'
  },
  loginLabel: {
    lineHeight: "32px",
    fontFamily: 'Inter-Bold, sans-serif',
    fontWeight: 700,
    fontSize: "24px",
    color: '#334155',
    textAlign: 'center' as const,
    margin: 'auto',
    textTransform: 'capitalize' as const
  },
  fieldsLabel: {
    textAlign: 'left' as const,
    lineHeight: "22px",
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: "16px",
    color: '#334155',
    textTransform: 'capitalize' as const
  },
  loginForm: {
    display: 'flex',
    alignItems: "center",
    marginTop:'20px'
  },
  text: {
    borderRadius: '8px'
  },
  forgotLabel: {
    lineHeight: "18px",
    fontFamily: 'Inter-Bold, sans-serif',
    fontWeight: 700,
    fontSize: "12px",
    color: '#334155',
    cursor:'pointer'
  },
  passwordSec: {
    display:'flex',
    alignItems:'center',
    justifyContent: 'space-between' as const
  },
  loginGrid: {
    padding:'10px',
    marginTop:'16px',
    borderRadius:'8px',
    textAlign: 'center' as const,
    backgroundColor:"#0E387A"
  },
  loginButton: {
    fontFamily: 'Inter-Bold, sans-serif',
    fontWeight: 700,
    fontSize: "16px",
    color: '#F1F5F9',
    lineHeight: "24px",
    textTransform: 'capitalize' as const ,
    padding:'16px',
    marginTop:'16px',
    borderRadius:'8px',
    textAlign: 'center' as const,
    backgroundColor:"#0E387A",
    width:'100%'
  },
  forgotPopup : {
    margin: '0 auto', 
    width:'327px', 
    backgroundColor:'#fff', 
    borderRadius:'8px', 
    padding: '20px', 
    textAlign:'center' as const
  },
  emailText: {
    lineHeight: "26px",
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: "18px",
    color: '#001133',
    marginBottom: '10px'
  },
  resetLink: {
    lineHeight: "24px",
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: "16px",
    color: '#0F172A'
  },
  resendBtn : {
    lineHeight: "22px",
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: "16px",
    color: '#0E387A',
    textTransform: 'none' as const,
    cursor:'pointer',
    margin: '24px 0 0'
  },
  error: {
    fontSize: "12px",
    lineHeight: '18px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    color: '#DC2626',
    marginTop:'6px',
    textAlign: 'left' as const 
  }
}

const CustomLoginBox = styled(Grid)({
  display: 'flex',
  flexDirection: 'column' as const,
  width: "420px",
  margin:'0 auto',
  gap:'20px',
  '@media (max-width: 600px)': {
    width: '320px'
  }
})

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    marginTop:'6px',
    borderRadius: '8px',
    fontSize: '15px'
  }
})

const StyledHelperText = styled(Typography)({
  lineHeight: "22px",
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  fontSize: "16px",
  color: '#0F172A',
  margin:'30px auto 0',
  textAlign:'center' as const,
  '@media (max-width: 600px)': {
    width: '320px'
  }
})

const StyledFormControl = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    lineHeight: "18px",
    fontFamily: 'Inter-Bold, sans-serif',
    fontWeight: 700,
    fontSize: "12px",
    color: '#334155'
  }
})

const CustomUnCheckBoxIcon = styled("div")({
  width: 20,
  height:20,
  borderRadius: '6px',
  borderColor: '#334155',
  border: '1px solid #334155'
})

const CustomCheckBoxIcon = styled("div")({
  width: 20,
  height:20,
  borderRadius: '6px',
  borderColor: '#334155',
  border: '1px solid #334155',
  backgroundColor: '#0E387A'
})

import { Form, Formik, FormikErrors, FormikTouched } from "formik";
// Customizable Area End

import EmailAccountLoginController, {
  configJSON,
  LoginFormValues,
  Props,
  Touched,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getErrorMessage = (
      touched: FormikTouched<Touched>,
      errors: FormikErrors<LoginFormValues>,
      value: string
    ) => {
      return (
        touched[value as keyof LoginFormValues] &&
        errors[value as keyof LoginFormValues] && (
          <Typography
            style={loginStyles.error}
          >
            {errors[value as keyof LoginFormValues]}
          </Typography>
        )
      );
  };
  
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <Grid container style={loginStyles.loginContainer}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography style={loginStyles.loginLabel}>{configJSON.loginBtnText}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Formik 
              initialValues={{ 
                email: localStorage.getItem("email") || "",
                password:"", 
                rememberMe: false
              }}
              validationSchema={this.loginSchema}
              onSubmit={(values: LoginFormValues) => {
                this.handleLogin(values)
              }}
            >
              {({values, touched, errors, handleChange}) => (
                <Form style={loginStyles.loginForm}>
                  <CustomLoginBox container >
                  {this.state.loginCommonErr.length > 0 &&
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={logStyles.alertBox}>
                              <Box sx={logStyles.errorBox}/>
                              <Box sx={logStyles.commonError} data-test-id="loginErr">
                              {this.state.loginCommonErr}
                              </Box>
                            </Box>
                          </Grid>
                        }
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl fullWidth>
                        <label style={loginStyles.fieldsLabel}>{configJSON.emailText}</label>
                        <StyledTextField
                          data-test-id="inputEmail"
                          name={configJSON.emailText}
                          type={configJSON.emailText}
                          placeholder={configJSON.yourEmailText}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(event)
                            this.setState({ loginCommonErr: '' })
                          }}
                        /> 
                        {this.getErrorMessage(touched, errors, "email")}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl fullWidth>
                        <label style={loginStyles.fieldsLabel}>Password</label>
                        <OutlinedInput
                          sx={{
                            '&.MuiOutlinedInput-root': {
                              fontSize:'15px',
                              marginTop:'6px',
                              borderRadius: '8px'
                            }
                          }}
                          data-test-id="inputPassword"
                          name="password"
                          type={this.state.showPassword ? "text" : "password"}
                          placeholder={"Your password"}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(event)
                            this.setState({ loginCommonErr: '' })
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label={
                                  this.state.showPassword ? 'hide the password' : 'display the password'
                                }
                                onClick={this.handleShowPassword}
                                onMouseDown={() => {}}
                                onMouseUp={() => {}}
                                edge="end"
                              >
                                {this.state.showPassword ?  <img src={imgPasswordVisible} /> : <img src={visibilityOff} /> }
                              </IconButton>
                            </InputAdornment>
                          }
                        /> 
                        {this.getErrorMessage(touched, errors, "password")}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={loginStyles.passwordSec}>
                      <StyledFormControl label="Remember Me" labelPlacement="end"
                        control={<Checkbox sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize:  20,
                            borderRadius: '6px',
                            display:'none'
                        }}}
                        icon={<CustomUnCheckBoxIcon />}
                        checkedIcon={<CustomCheckBoxIcon />}
                        name="rememberMe"/>}
                        data-test-id="rememberMe"
                        onChange={this.handleLoginRemember}
                      />
                      <Typography style={loginStyles.forgotLabel} data-test-id="forgotBtn" onClick={this.handleForgotPassword}>{configJSON.forgotPasswordText}?</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                      <Button type="submit" data-test-id="loginBtn" style={loginStyles.loginButton}>{configJSON.loginBtnText}</Button>
                    </Grid>
                  </CustomLoginBox>
                </Form>
              )}
            </Formik>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StyledHelperText>{configJSON.loginHelperText}</StyledHelperText>
            </Grid>
          </Grid>
        </Grid>
      </>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
