// Customizable Area Start
import React from "react";
import EmailAccountSignupController, { configJSON, FormPassValues, FormValues, Props, Touched, Error } from "./EmailAccountSignupController.web";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import { loginStyles as signupStyles } from "./EmailAccountLoginBlock.web";
import { Button, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, OutlinedInput, Checkbox, styled, TextField, Typography, Box } from "@mui/material";
import { imageCheck, imageUnCheck, imgPasswordVisible, visibilityOff } from "./assets";
// Customizable Area End

export default class EmailAccountSignup extends EmailAccountSignupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          style={customStyles.errorText}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  renderSetPassword = () => {
    return(
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} style={customStyles.passwordContainer}>
          <Typography style={customStyles.signUpLabel}>{configJSON.setPasswordText}</Typography>
          <Typography style={customStyles.helperText}>{configJSON.createPasswordHelperText}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Formik 
            initialValues={{ 
              email: "",
              newPassword:"",
              confirmPassword : ""
            }}
            validationSchema={this.setPasswordSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values: FormPassValues) => {
              this.handleSetPassword(values)
            }}
          >
            {({touched, errors,values, handleChange}) => (
              <Form style={signupStyles.loginForm} noValidate>
                <StyledPassGrid container >
                  {this.state.setPasswordError.length > 0 &&
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box sx={customStyles.alertBox}>
                        <Box sx={customStyles.errorBox}/>
                        <Box data-test-id="setPasswordError" sx={customStyles.commonError}>
                        {this.state.setPasswordError}
                        </Box>
                      </Box>
                    </Grid>
                  }
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl fullWidth>
                      <label style={signupStyles.fieldsLabel}>{configJSON.setEmailAddressText}</label>
                      <TextField
                        autoComplete="off"
                        name={"email"}
                        data-test-id="setEmail"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                            marginTop:'6px',
                            fontSize: '15px'
                          }
                        }}
                        type={configJSON.emailText}
                        placeholder={configJSON.yourEmailAddText}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(event)
                          this.setState({ setPasswordError: ''})
                        }}
                      /> 
                      {this.getErrorMessage(touched, errors, "email")}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl fullWidth>
                      <label style={signupStyles.fieldsLabel}>{configJSON.newPasswordText}</label>
                      <OutlinedInput
                        sx={{
                          '&.MuiOutlinedInput-root': {
                            fontSize:'15px',
                            borderRadius: '8px',
                            marginTop:'6px'
                          }
                        }}
                        data-test-id="newPassword"
                        value={this.state.newPassword}
                        name={configJSON.newPasswordTxt}
                        type={this.state.showNewPassword ? "text" : "password"}
                        placeholder={"Your password"}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(event)
                          this.handleNewPassword(event.target.value)
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={
                                this.state.showNewPassword ? 'hide the password' : 'display the password'
                              }
                              onClick={this.handleShowNewPassword}
                              onMouseDown={() => {}}
                              onMouseUp={() => {}}
                              edge="end"
                            >
                              {this.state.showNewPassword ?  <img src={imgPasswordVisible} /> : <img src={visibilityOff} /> }
                            </IconButton>
                          </InputAdornment>
                        }
                      /> 
                      {this.getErrorMessage(touched, errors, "newPassword")}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl fullWidth>
                      <label style={signupStyles.fieldsLabel}>{configJSON.confirmNewPasswordText}</label>
                      <OutlinedInput
                        sx={{
                          '&.MuiOutlinedInput-root': {
                            marginTop:'6px',
                            fontSize:'15px',
                            borderRadius: '8px'
                          }
                        }}
                        data-test-id="confirmPassword"
                        name={configJSON.confirmPasswordTxt}
                        type={this.state.showConfirmPassword ? "text" : "password"}
                        placeholder={"Your password"}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(event)
                          this.setConfirmPassword(event.target.value)
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={
                                this.state.showConfirmPassword ? 'hide the password' : 'display the password'
                              }
                              onClick={this.handleConfirmPassword}
                              onMouseDown={() => {}}
                              onMouseUp={() => {}}
                              edge="end"
                            >
                              {this.state.showConfirmPassword ?  <img src={imgPasswordVisible} /> : <img src={visibilityOff} /> }
                            </IconButton>
                          </InputAdornment>
                        }
                      /> 
                      {this.getErrorMessage(touched, errors, "confirmPassword")}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <StyledFormControlLabel label={configJSON.containUpperCaseLetter} labelPlacement="end"
                          control={<Checkbox sx={{
                            "& .MuiSvgIcon-root": {
                              borderRadius: '6px',
                              display:'none'
                          }}}
                          icon={<img src={imageUnCheck} />}
                          checked={this.state.validations.hasUpperCase}
                          checkedIcon={<img src={imageCheck} />}
                          />}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <StyledFormControlLabel label={configJSON.containLowerCaseLetter} labelPlacement="end"
                          control={<Checkbox sx={{
                            "& .MuiSvgIcon-root": {
                              borderRadius: '6px',
                              display:'none'
                          }}}
                          icon={<img src={imageUnCheck} />}
                          checked={this.state.validations.hasLowercase}
                          checkedIcon={<img src={imageCheck} />}
                          />}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <StyledFormControlLabel label={configJSON.containSymbolOrNumber} labelPlacement="end"
                          control={<Checkbox sx={{
                            "& .MuiSvgIcon-root": {
                              borderRadius: '6px',
                              display:'none'
                          }}}
                          icon={<img src={imageUnCheck} />}
                          checked={this.state.validations.hasNumberOrSymbol}
                          checkedIcon={<img src={imageCheck} />}
                          />}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <StyledFormControlLabel label={configJSON.contain12CharacterMin} labelPlacement="end"
                          control={<Checkbox sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize:  20,
                              borderRadius: '6px',
                              display:'none'
                          }}}
                          icon={<img src={imageUnCheck} />}
                          checked={this.state.validations.isLongEnough}
                          checkedIcon={<img src={imageCheck} />}
                          />}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button data-test-id="submitBtn" type="submit"
                    disabled={this.handleSubmitButton()} 
                    style={signupStyles.loginButton}>{configJSON.signUpBtnText}</Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <StyledRememberFormControl label="Remember Me" labelPlacement="end"
                      control={<Checkbox sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize:  20,
                          borderRadius: '6px',
                          display:'none'
                      }}}
                      icon={<StyledUnCheckBoxIcon />}
                      checkedIcon={<StyledCheckBoxIcon />}
                      name="remember me"/>}
                      onChange={this.handleRememberMe}
                      />
                  </Grid>  
                </StyledPassGrid>
              </Form>
            )}
          </Formik>
        </Grid>  
      </>
    )
  }
  // Customizable Area End
  render(){
        // Customizable Area Start
        // Customizable Area End
    return(
        // Customizable Area Start
        <>
          <Grid container style={signupStyles.loginContainer}>
            {!this.state.setPassword && 
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography style={signupStyles.loginLabel}>{configJSON.signUpBtnText}</Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Formik 
                    initialValues={{ 
                      emailAddress: "",
                      tempPassword: ""
                    }}
                    validationSchema={this.signUpSchema}
                    validateOnBlur={true}
                    validateOnChange={true}
                    onSubmit={(values: FormValues) => {
                      this.handleSignup(values)
                    }}
                  >
                    {({values, touched, errors, handleChange, handleBlur,setFieldValue }) => (
                      <Form style={signupStyles.loginForm} noValidate>
                        <StyledGrid container >
                        {this.state.signUpError &&
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={customStyles.alertBox}>
                              <Box sx={customStyles.errorBox}/>
                              <Box data-test-id="signupError" sx={customStyles.commonError}>
                              {this.state.signUpError}
                              </Box>
                            </Box>
                          </Grid>
                        }
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl fullWidth>
                              <label style={signupStyles.fieldsLabel}>{configJSON.emailText}</label>
                              <StyledEmail
                                autoComplete="off"
                                name={configJSON.emailAddressTxt}
                                type={configJSON.emailText}
                                placeholder={configJSON.yourEmailText}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  handleChange(event)
                                  this.setState({signUpError: ''})
                                }}
                                data-test-id="emailAddress"
                              /> 
                              {this.getErrorMessage(touched,errors,"emailAddress")}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl fullWidth>
                              <label style={signupStyles.fieldsLabel}>{configJSON.temporaryPasswordText}</label>
                              <OutlinedInput
                                sx={{
                                  '&.MuiOutlinedInput-root': {
                                    marginTop:'6px',
                                    borderRadius: '8px',
                                    fontSize:'15px'
                                  }
                                }}
                                defaultValue={values.tempPassword}
                                name={configJSON.tempPasswordTxt}
                                type={this.state.showSignUpPassword ? "text" : "password" }
                                placeholder={configJSON.yourTempPasswordText}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  handleChange(event)
                                  setFieldValue("tempPassword", event.target.value)
                                  this.setState({ signUpError: '' })
                                }}
                                onBlur={handleBlur}
                                autoComplete="current-password"
                                data-test-id="password"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label={
                                        this.state.showSignUpPassword ? 'hide the password' : 'display the password'
                                      }
                                      onClick={this.handleShowTempPassword}
                                      onMouseDown={() => {}}
                                      onMouseUp={() => {}}
                                      edge="end"
                                    >
                                      {this.state.showSignUpPassword ?  <img src={imgPasswordVisible} /> : <img src={visibilityOff} /> }
                                    </IconButton>
                                  </InputAdornment>
                                }
                              /> 
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button type="submit" data-test-id="signUpBtn" style={signupStyles.loginButton}>{configJSON.submitBtnText}</Button>
                          </Grid>
                        </StyledGrid>
                      </Form>
                    )}
                  </Formik>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <StyledText>{configJSON.loginHelperText}</StyledText>
                  </Grid>
                </Grid>
              </>
            }
            {this.state.setPassword && this.renderSetPassword()}
          </Grid>
        </>
        // Customizable Area End
    )
  }
}

// Customizable Area Start

export const customStyles = {
  helperText: {
    lineHeight: "21.09px",
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: "18px",
    color: '#334155',
    margin:'20px auto 0'
  },
  passwordContainer: {
    textAlign: 'center' as const
  },
  signUpLabel: {
    color: '#334155',
    textAlign: 'center' as const,
    lineHeight: "32px",
    fontFamily: 'Inter-Bold, sans-serif',
    fontWeight: 700,
    fontSize: "24px",
    textTransform: 'capitalize' as const,
    marginTop:'70px'
  },
  errorText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: '18px',
    color: '#DC2626',
    marginTop:'6px',
    textAlign: 'left' as const 
  },
  alertBox: { 
    display:'flex', 
    alignItems: 'center', 
    height:'38px', 
    borderRadius: '4px', 
    backgroundColor: '#FEE2E2', 
    position: 'relative'
  },
  errorBox: {
    backgroundColor: 'rgb(220, 38, 38)',
    width: '4px',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    position:'absolute',
    left: 0,
    top: 0,
    bottom: 0
  },
  commonError: {  
    marginLeft : '20px', 
    fontFamily: 'Inter,sans-serif', 
    fontWeight: 400, 
    fontSize:'12px', 
    lineHeight: '8px', 
    color: '#DC2626'
  }
}

const StyledRememberFormControl = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    lineHeight: "21.03px",
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: "13.38px",
    color: '#0F172A'
  }
})

const StyledFormControlLabel = styled(FormControlLabel)({
  cursor: 'default',
  span: {
    lineHeight: "21.06px",
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: "13.4px",
    color: '#0F172A'
  }
})

const StyledUnCheckBoxIcon = styled("div")({
  borderRadius: '6px',
  borderColor: '#64748B',
  width: 19.12,
  height: 19.12,
  border: '1px solid #64748B'
})

const StyledCheckBoxIcon = styled("div")({
  width: 20,
  border: '1px solid #64748B',
  backgroundColor: '#64748B',
  height: 19.12,
  borderRadius: '6px',
  borderColor: '#64748B'
})

const StyledText = styled(Typography)({
  fontSize: "16px",
  color: '#0F172A',
  margin:'30px auto 0',
  lineHeight: "22px",
  fontFamily: 'Inter, sans-serif',
  fontWeight: '400',
  textAlign:'center' as const,
  '@media (max-width: 600px)': {
    width: '320px'
  }
})

const StyledEmail = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    marginTop:'6px',
    fontSize: '15px'
  }
})

const StyledGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column' as const,
  width: "420px",
  margin:'0 auto',
  gap:'20px',
  '@media (max-width: 600px)': {
    width: '320px'
  }
})

const StyledPassGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column' as const,
  width: "314px",
  margin:'20px auto 0',
  gap:'20px',
  '@media (max-width: 600px)': {
    width: '320px'
  }
})
// Customizable Area End

