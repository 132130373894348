// Customizable Area Start
import React from "react";
import { Box, Button, FormControl, Grid, styled, TextField, Typography } from "@mui/material";
import ResetPasswordController, { configJSON, Props } from "./ResetPasswordController.web";
import { customStyles } from "../../../blocks/email-account-login/src/EmailAccountSignup.web";
import { opNotesLogo } from "./assets";
import { ToastContainer } from "react-toastify";
// Customizable Area End

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderCommonErrors = () => {
    return(
      <>
      {this.state.apiError.length > 0 &&
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box sx={{...customStyles.alertBox, marginTop:'20px'}}>
            <Box sx={customStyles.errorBox}/>
            <Box data-test-id="apiError" sx={customStyles.commonError}>
            {this.state.apiError}
            </Box>
          </Box>
        </Grid>
      }
      </>
    )
  }

  renderResendEmail = () => {
    return(
    <>
      <Box style={customPassStyles.emailPopup}>
        <Typography style={customPassStyles.headerOne} data-test-id="checkEmail">{configJSON.checkEmailHeaderText}</Typography>
        <Typography style={{marginTop:'10px', ...customPassStyles.resetLinkHeading}}>{configJSON.resetPasswordParaText}</Typography>
        <Typography style={customPassStyles.resetLinkHeading}>{this.state.recoveryEmail}</Typography>
        <Button style={customPassStyles.loginBtn} onClick={this.handleResendEmail}>{configJSON.resendEmailBtnLabel}</Button>
        <Typography style={customPassStyles.resetBackBtn}>{configJSON.backText} <span style={customPassStyles.loginBtn} onClick={this.handleBackToLogin}>{configJSON.logInBtnText}</span></Typography>
      </Box>
    </>
   )
  }
  // Customizable Area End
  render(){
        // Customizable Area Start
        // Customizable Area End
    return(
        // Customizable Area Start
        <>
        <ToastContainer/>
        {!this.state.resendEmailPopup ?
          <>
            <Grid container>
              <Box style={customPassStyles.logoBox}>
                <img src={opNotesLogo} alt="opNotes"/>
              </Box>
            </Grid>
            <Grid container style={customPassStyles.mainContainer}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography style={customPassStyles.headerText}>{"Password Recovery"}</Typography>
                <Typography style={customPassStyles.subHeaderText}>{"Enter your registered email below to receive password reset a recovery link"}</Typography>
              </Grid>
              {this.renderCommonErrors()}
              <FormControl fullWidth>
                <label style={customPassStyles.label}>{configJSON.emailLabel}</label>
                <EmailTextField
                  data-test-id="recoveryEmail"
                  placeholder={configJSON.emailPlaceholder}
                  name={configJSON.emailText}
                  type={configJSON.emailText}
                  value={this.state.recoveryEmail}
                  onChange={this.handleEmail}
                />
                {this.state.emailError && <Typography data-test-id="emailError" style={customPassStyles.errorMsg}>{this.state.emailError}</Typography>}
              </FormControl>
              <Button data-test-id="recoveryEmailBtn" style={customPassStyles.buttonBox} onClick={this.handleRecoveryLink} disabled={this.state.recoveryEmail.length === 0  || this.state.emailError.length > 0}>{configJSON.sendRecoveryLinkText}</Button>
              <Typography style={customPassStyles.backBtn}>{configJSON.backText} <span data-test-id="backBtn" style={customPassStyles.loginBtn} onClick={this.handleBackToLogin}>{configJSON.logInBtnText}</span></Typography>
            </Grid>
          </>
          :
          <>
            <Grid container style={customPassStyles.mainContainer}>
              {this.renderResendEmail()}
            </Grid>
          </>
        }
        </>
        // Customizable Area End
    )
  }
}

// Customizable Area Start
export const customPassStyles = {
  logoBox: {
    padding: '24px',
    width: '100%',
    backgroundColor: '#fff'
  },
  headerText: {
    fontFamily: 'Inter-bold, sans-serif',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    color: '#334155'
  },
  subHeaderText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#0F172A',
    marginTop: '14px'
  },
  mainContainer: {
    margin: 'auto',
    maxWidth: '327px',
    width: '100%',
    textAlign: 'center' as const
  },
  label: {
    textTransform: 'capitalize' as const,
    textAlign:'left' as const,
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    color: '#334155',
    marginTop: '20px'
  },
  buttonBox: {
    fontSize: "16px",
    color: '#F1F5F9',
    borderRadius:'8px',
    textAlign: 'center' as const,
    backgroundColor:"#0E387A",
    width:'100%',
    lineHeight: "24px",
    textTransform: 'none' as const ,
    fontFamily: 'Inter-Bold, sans-serif',
    fontWeight: 700,
    padding:'16px',
    marginTop:'30px',
  },
  backBtn: {
    textAlign: 'center' as const,
    margin: '30px auto 0',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: '#0F172A'
  },
  loginBtn: {
    lineHeight: "22px",
    textAlign: 'center' as const,
    color: '#0E387A',
    textTransform: 'none' as const,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: "16px",
    cursor:'pointer',
    margin: '24px 0 0'
  },
  emailPopup: {
    margin: '0 auto', 
    width:'327px', 
    backgroundColor:'#fff', 
    borderRadius:'8px', 
    padding: '20px', 
    textAlign:'center' as const
  },
  headerOne: {
    textTransform: 'none' as const,
    fontSize: "18px",
    lineHeight: '26px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    color: '#001133'
  },
  resetLinkHeading : {
    fontSize: "16px",
    lineHeight: '24px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    color: '#0F172A'
  },
  resetBackBtn: {
    margin: '20px auto 0',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    textAlign: 'center' as const,
    color: '#0F172A',
    fontSize: "16px",
    lineHeight: "22px"
  },
  errorMsg: {
    fontSize: "12px",
    lineHeight: '18px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    color: '#DC2626',
    marginTop:'6px',
    textAlign: 'left' as const 
  }
}

const EmailTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    marginTop:'6px',
    borderRadius: '8px',
    fontSize: '16px'
  }
}) 
// Customizable Area End

 
