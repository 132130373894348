Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.forgotPassword = "Forgot password?";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.loginBtnText = "login";
exports.emailText = "email";
exports.yourEmailText = "Your email";
exports.forgotPasswordText = "Forgot Password";
exports.loginHelperText = "If you do not yet have an account, please contact your administrator.";
exports.checkEmailText = "Check your email";
exports.resetPasswordText = "We have sent a password reset link to";
exports.resendEmailBtnText = "Resend e-mail";
exports.backToText = "Back to";
exports.logInBtn = "Log in";
exports.submitBtnText = "Submit";
exports.temporaryPasswordText = "Temporary Password";
exports.yourTempPasswordText = "Your Temporary password";
exports.signUpBtnText = "Sign Up";
exports.setPasswordText = "Set Password";
exports.createPasswordHelperText = "Create a new password to complete your sign up."
exports.tempPasswordTxt = "tempPassword";
exports.emailAddressTxt = "emailAddress";
exports.emailAddTxt = "emailAdd";
exports.setEmailAddressText = "Email Address";
exports.yourEmailAddText = "Your email address";
exports.newPasswordText = "New Password";
exports.newPasswordTxt = "newPassword";
exports.confirmPasswordTxt = "confirmPassword";
exports.confirmNewPasswordText = "Confirm your new password";
exports.containLowerCaseLetter = "At least one lowercase letter";
exports.containUpperCaseLetter = "At least one capital letter";
exports.contain12CharacterMin = "Minimum character length is 12";
exports.containSymbolOrNumber = "At least one special character and number";
exports.signUpApiEndpoint = "account_block/accounts/sign_up";
exports.setPasswordEndpoint = "bx_block_forgot_password/passwords/set_new_password";
exports.loginApiEndpoint = "bx_block_login/logins";
// Customizable Area End
