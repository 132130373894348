import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Drawer,
  Grid,
  styled,
  OutlinedInput,
  FormControl
} from "@mui/material";
import {
  createTheme
} from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from '@mui/icons-material/Menu';
import { logo, search } from "./assets";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  list = () => (
    <div
      className="left"
      style={{
        width: 250,
      }}
      role="presentation"
      onClick={this.toggleDrawer}
      onKeyDown={this.toggleDrawer}
    >
      <Box style={webStyle.closeBox}>
        <CloseIcon style={webStyle.close} onClick={this.toggleDrawer} />
      </Box>
      <Box style={webStyle.userProfileWrapper}>
        <StyledMenu>{configJSON.createPatientAccountText}</StyledMenu>
        <StyledMenu>{configJSON.practiceDashboardText}</StyledMenu>
        <StyledMenu>{configJSON.chatText}</StyledMenu>
        <StyledMenu>{configJSON.signOutText}</StyledMenu>
      </Box>
    </div>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
            <Grid container style={webStyle.wrapper}>
              <Grid item xs={2} sm={4} md={4} lg={4}>
                <img alt="logo" src={logo} style={webStyle.logo}/>
              </Grid>
              <MenuGrid item xs={12} sm={8} md={8} lg={8}>
                <StyledMenu>{configJSON.createPatientAccountText}</StyledMenu>
                <StyledMenu>{configJSON.practiceDashboardText}</StyledMenu>
                <StyledMenu>{configJSON.chatText}</StyledMenu>
                <FormControl>
                <OutlinedInput 
                  data-test-id="search"
                  sx={{
                    '&.MuiOutlinedInput-root': {
                      width: '248px',
                      height: '45px',
                      paddingLeft: '6px',
                      borderRadius: '6px'
                    },
                    'input': {
                      marginLeft: '-6px'
                    }
                  }}
                  value={this.state.searchValue}
                  placeholder="Search"
                  onChange={this.handleSearch}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton><img src={search} alt="search"/></IconButton>
                    </InputAdornment>
                  }
                />
                </FormControl>
                <StyledMenu>{configJSON.signOutText}</StyledMenu>
              </MenuGrid>
              <MenuIconGrid item xs={8} sm={8} md={8} lg={8}>
                <MenuIcon data-test-id="optionsMenu" style={webStyle.close} onClick={this.toggleDrawer}/>
              </MenuIconGrid>
            </Grid>
            <Drawer
              anchor={"left"}
              open={this.state.webDrawer}
              onClose={this.toggleDrawer}
            >
              {this.list()}
            </Drawer>
            </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    padding: '30px 10px',
    gap:'8px'
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
  logo: {
    height: '27px',
    textAlign: 'left' as const
  },
  navContainer: {
    padding: '0'
  },
  wrapper: {
    padding: '16px', 
    margin: '0',
    backgroundColor: '#fff',
    height: '75px',
    display: 'flex',
    alignItems: 'center'
  },
  close: {
    cursor: 'pointer' as const
  },
  closeBox: {
    textAlign: 'right' as const, 
    padding:'10px'
  }
};

const MenuIconGrid = styled(Grid)({
  display: 'none',
  '@media (max-width: 1200px)': {
    display:'flex', 
    alignItems: 'center', 
    justifyContent:'end'
  }
})

const StyledMenu = styled(Typography)({
  fontFamily : 'Inter, sans-serif',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '21.09px',
  color: '#334155',
  cursor:'pointer',
  '@media (max-width: 600px)': {
    fontSize: '16px'
  },
  '@media (max-width: 400px)': {
    fontSize: '14px'
  }
})

const MenuGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media (max-width: 1200px)': {
    display: 'none'
  }
})
// Customizable Area End
