// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
export const configJSON = require("./config");

export interface Touched {
  emailAddress: boolean,
  tempPassword: boolean,
  newPassword: boolean,
  confirmPassword : boolean,
  email: boolean
}

export interface Error {
  emailAddress: string,
  tempPassword: string,
  newPassword: string,
  confirmPassword : string,
  email: string
}

export interface FormValues {
  emailAddress: string,
  tempPassword: string
}

export interface FormPassValues {
  email: string,
  newPassword: string,
  confirmPassword: string
}

interface SignUpResponse {
  meta: {
    message: string,
  }
  errors: {
    message: string
  }[],
  message: string
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showSignUpPassword: boolean,
  setPassword: boolean,
  showNewPassword: boolean,
  showConfirmPassword: boolean,
  newPassword: string,
  validations: {
    hasLowercase : boolean,
    hasUpperCase : boolean,
    hasNumberOrSymbol: boolean,
    isLongEnough: boolean
  },
  emailAddress: string,
  tempPassword: string,
  signUpError: string,
  confirmPassword: string,
  setPasswordError: string,
  rememberMe: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountSignupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signupApiCallId: string = "";
  setPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      showSignUpPassword: false,
      showNewPassword: false,
      setPassword: false,
      showConfirmPassword: false,
      newPassword: '',
      validations: {
        hasLowercase : false,
        hasUpperCase : false,
        hasNumberOrSymbol: false,
        isLongEnough: false
      },
      emailAddress: '',
      tempPassword: '',
      signUpError: '',
      confirmPassword: '',
      setPasswordError: '',
      rememberMe: false
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } 
        else this.handleApiResponse(responseJson, apiRequestCallId)
      }
    }
    // Customizable Area End
  }

  handleApiResponse = (responseJson: unknown, apiRequestCallId:string) => {
    if (apiRequestCallId === this.signupApiCallId) {
      let response = responseJson as SignUpResponse
      if(response.errors && response.errors[0]) {
        this.setState({ signUpError: response.errors[0].message })
      }
      if(response.meta && response.meta.message === "Login successful.") {
        setTimeout(() => {
          this.setState({ setPassword : true})
        }, 500)
      }
    }

    if (apiRequestCallId === this.setPasswordApiCallId) {
      let response = responseJson as SignUpResponse
      if(response.errors && response.errors[0]) {
        this.setState({ setPasswordError: response.errors[0].message })
      }
      if(response.message === "Account not found") {
        this.setState({ setPasswordError: 'Account not found'})
      }
      if(response.message === "You have created your new password") {
        setTimeout(() => {
          this.props.navigation.navigate("EmailAccountLoginBlock");
        }, 500)
      }
    }
  }

  handleShowTempPassword = () => {
    this.setState({ showSignUpPassword: !this.state.showSignUpPassword })
  }

  handleSignup = (values: FormValues) => {
    this.setState({ signUpError: ''})
    if(values) {
      this.createSignup(values)
    }
  }

  createSignup = (values: FormValues) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      data: {
        type: "email_account",
        email: values.emailAddress,
        password: values.tempPassword
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  setPassword = async(values: FormPassValues) => {
    const headers = {
    };

    const formData = new FormData()
   
    formData.append("email",values.email )
    formData.append("set_new_password",values.newPassword )
    formData.append("confirm_password",values.confirmPassword )

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setPasswordEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    if(this.state.rememberMe) {
      localStorage.setItem("email", values.email)
    }
    else {
      localStorage.removeItem("email")
    }
    return true;
  }

  handleShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  }

  handleConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
  }

  handleSetPassword = (values: FormPassValues) => {
    if(values) {
      this.setPassword(values)
    }
  }

  handleNewPassword = (password: string) => {
    this.setState({
      newPassword: password,
      setPasswordError: '', 
      validations: {
        hasLowercase : /[a-z]/.test(password),
        hasUpperCase : /[A-Z]/.test(password),
        hasNumberOrSymbol: /^(?=.*\d)(?=.*[^\w\s]).+$/.test(password),
        isLongEnough: password.length >= 12
      }
    })
  }

  setConfirmPassword = (value: string) => {
    this.setState({ confirmPassword: value, setPasswordError: '' })
  }

  signUpSchema = () => {
    return Yup.object().shape({
      emailAddress : Yup.string()
      .email("Invalid email")
      .required("Email is required")
    })
  }

  setPasswordSchema = () => {
    return Yup.object().shape({
    email : Yup.string()
      .email("Invalid email")
      .required("Email is required"),
      newPassword: Yup.string()
      .required("New password is required"),
      confirmPassword: Yup.string()
      .required("Confirm password is required")
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Password must match"
        ),
      }),
    })
  }

  handleSubmitButton = () => {
    const { hasLowercase, hasNumberOrSymbol, hasUpperCase, isLongEnough }  = this.state.validations
    let isValidate = hasLowercase && hasNumberOrSymbol && hasUpperCase && isLongEnough
    const isPasswordMatching = this.state.newPassword === this.state.confirmPassword
    const disableButton = isPasswordMatching && isValidate
    return !disableButton
  }

  handleRememberMe = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    this.setState({ rememberMe: checked })
  }

  // Customizable Area End
}
